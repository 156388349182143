<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Overview" active>
          <form>
            <h5>
              Mash details
              <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
            </h5>

            <hr />
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.id.id"
                  :value="data.id"
                  :label="controls.id.label"
                  :readonly="controls.id.readonly"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-select
                  :id="controls.base_product.id"
                  :value="data.base_product"
                  :label="controls.base_product.label"
                  :readonly="controls.base_product.readonly"
                  :options="controls.base_product.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-select
                  :id="controls.mash_tank.id"
                  :value="data.mash_tank"
                  :label="controls.mash_tank.label"
                  :readonly="controls.mash_tank.readonly"
                  :options="controls.mash_tank.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-date-picker
                  :id="controls.date_started.id"
                  :value-single="data.date_started"
                  :label="controls.date_started.label"
                  :readonly="controls.date_started.readonly"
                  :mode="mode"
                  @changed="updateDateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.amount_mashed.id"
                  :value="data.amount_mashed"
                  :label="controls.amount_mashed.label"
                  :readonly="controls.amount_mashed.readonly"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.amount_mashed_remaining.id"
                  :value="data.amount_mashed_remaining"
                  :label="controls.amount_mashed_remaining.label"
                  :readonly="controls.amount_mashed_remaining.readonly"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.brix_in.id"
                  :value="data.brix_in"
                  :label="controls.brix_in.label"
                  :readonly="controls.brix_in.readonly"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.brix_out.id"
                  :value="data.brix_out"
                  :label="controls.brix_out.label"
                  :readonly="controls.brix_out.readonly"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.created_by.id"
                  :value="data.created_by"
                  :label="controls.created_by.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.modified_by.id"
                  :value="data.modified_by"
                  :label="controls.modified_by.label"
                  :readonly="true"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.created.id"
                  :value="data.created"
                  :label="controls.created.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.modified.id"
                  :value="data.modified"
                  :label="controls.modified.label"
                  :readonly="true"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <inline-input
                  :id="controls.description.id"
                  :value="data.description"
                  :label="controls.description.label"
                  :readonly="controls.description.readonly"
                  @changed="updateField"
                  :is-text-area="true"
                  :mode="mode"
                />
              </b-col>
            </b-row>
          </form>
          <hr />

          <b-row>
            <b-col>
              <mash-items-table
                ref="mash-items-table"
                :product-id="this.id"
                :mode="mode"
              ></mash-items-table>
            </b-col>
          </b-row>

          <form-submission-actions
            v-if="action !== 'preview'"
            :mode="mode"
            :loading="{
              save: saveInProgress,
              saveAndView: saveAndViewInProgress
            }"
            :buttons-visibility="{
              previous: $customTable.getPrevKey(dataset, id),
              next: $customTable.getNextKey(dataset, id)
            }"
            @previous-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getPrevKey(dataset, id)
                }
              })
            "
            @next-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getNextKey(dataset, id)
                }
              })
            "
            @save="
              save('tabular').then(response =>
                response ? $router.push({ name: 'Mashing' }) : false
              )
            "
            @save-and-view="
              save('view').then(response =>
                response
                  ? $router.push({
                      name: 'Mash submission',
                      params: { action: 'view', id: response }
                    })
                  : false
              )
            "
            @edit="
              $router.push({
                name: 'Mash submission',
                params: { action: 'edit', id: id }
              })
            "
            @back="$router.push($store.getters['router/previousRoute'])"
          />
        </b-tab>
        <b-tab
          :title="controls.tabs.fermentations.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <fermentation-table
            :mash-id="this.id"
            :show-add-button="+data.amount_mashed_remaining > 0"
            @loaded="onFermentationsTableLoad"
          ></fermentation-table>
        </b-tab>
        <b-tab
          :title="controls.tabs.distillations.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <distillation-table
            :mash-id="this.id"
            :show-add-button="false"
            @loaded="onDistillationsTableLoad"
          ></distillation-table>
        </b-tab>
        <b-tab
          :title="controls.tabs.report.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
          @click="onMashingReportTabClick"
        >
          <mashing-report ref="mashing-report" :mash-id="this.id">
          </mashing-report>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";

import CKEditor from "@ckeditor/ckeditor5-vue";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
Vue.use(CKEditor);

import MashItemsTable from "@/views/Production/Mashing/MashItemsTable.vue";
import FermentationTable from "@/views/Production/Fermentation/FermentationTable.vue";
import DistillationTable from "@/views/Production/Distillation/DistillationTable.vue";
import MashingReport from "@/views/Production/Mashing/MashingAutoLogReport.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "MashSubmissionForm",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    MashItemsTable,
    FermentationTable,
    DistillationTable,
    MashingReport
  },
  data: function() {
    return {
      dataset: {
        name: "mashing"
      },
      mode: undefined,
      baseApiUrl: "mash",
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      editor: InlineEditor,
      supplierPreviewModal: {
        id: "",
        title: ""
      },
      controls: {
        tabs: {
          fermentations: {
            title: "Fermentations"
          },
          distillations: {
            title: "Distillations"
          },
          report: {
            title: "Report"
          }
        },

        editor: {
          data: "",
          config: {
            startupFocus: true,
            startupShowBorders: true
            // readOnly: true,
          },
          readonly: false
        },
        id: {
          id: "input:ID",
          label: "Product ID",
          changed: false,
          readonly: true
        },
        base_product: {
          id: "select:base_product",
          label: "Base product",
          options: [],
          changed: false,
          readonly: false,
          loading: false
        },
        mash_tank: {
          id: "select:mash_tank",
          label: "Mash tank",
          options: [],
          changed: false,
          readonly: false,
          loading: false
        },

        date_started: {
          id: "datepicker:date_started",
          label: "Date Started",
          changed: false,
          readonly: false,
          required: true
        },

        amount_mashed: {
          id: "input:amount_mashed",
          label: "Amount Mashed",
          changed: false,
          readonly: false
        },
        amount_mashed_remaining: {
          id: "input:amount_mashed_remaining",
          label: "Amount Mashed Remaining",
          changed: false,
          readonly: false
        },
        brix_in: {
          id: "input:brix_in",
          label: "Brix In",
          changed: false,
          readonly: false
        },
        brix_out: {
          id: "input:brix_out",
          label: "Brix Out",
          changed: false,
          readonly: false
        },
        description: {
          id: "input:description",
          label: "Notes",
          changed: false,
          readonly: false
        },

        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: true
        },
        modified: {
          id: "input:modified",
          label: "Modified",
          changed: false,
          readonly: true
        },
        created_by: {
          id: "input:created_by",
          label: "Created By",
          changed: false,
          readonly: true
        },
        modified_by: {
          id: "input:modified_by",
          label: "Modified By",
          changed: false,
          readonly: true
        }
      },
      data: {
        id: "",
        base_product: {
          id: "",
          label: ""
        },
        mash_tank: {
          id: "",
          label: ""
        },
        date_started: "",
        amount_mashed: "",
        amount_mashed_remaining: "",
        brix_in: "",
        brix_out: "",
        created_by: "",
        modified_by: "",
        created: "",
        modified: "",
        description: ""
      },
      selectAll: false,
      selectedRows: [],
      dropdowns: {},
      dblclick: undefined,
      serverFilter: undefined
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    async initialize() {
      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.data.id = this.id;

      this.loadDictionaries();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.loadData(this.id);
      }

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.drawDataTable();
      }
    },
    drawDataTable() {
      //this.$refs["mash-items-table"].getData(payload);
    },
    onInlineDropdownChange() {},
    onInlineColumnUpdate() {},
    onInlineUpdate() {},
    loadDictionaries() {
      let self = this;

      const mash_tanks = () => {
        this.$api.get("dictionaries/mash-tanks").then(response => {
          self.controls.mash_tank.options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
      };
      const baseproducts = async () => {
        let response = await this.$api.get("dictionaries/base-products");
        self.controls.base_product.options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      Promise.all([baseproducts(), mash_tanks()]);
    },
    edit() {},

    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let method = "";

      let url = this.baseApiUrl;

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
        url = this.baseApiUrl;
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = "put";
        url = `${this.baseApiUrl}/${this.data.id}`;
      }

      this.data.items = this.$refs["mash-items-table"].getDataSet();

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateField(field, value, mode) {
      this.$form.updateField(this.baseApiUrl, this, field, value, mode);
    },
    updateSelect(id, value) {
      this.$form.updateField(this.baseApiUrl, this, id, value, this.mode);
    },
    async loadData(id) {
      let self = this;

      this.isLoading = true;

      try {
        let response = await this.$api.get(`${this.baseApiUrl}/${id}`);

        this.isLoading = false;

        if (self.$_.isEmpty(response)) return;

        this.data.id = response["ID"];

        this.data.date_started = response["Date Started"];
        this.data.created = response["Created"];
        this.data.modified = response["Modified"];

        this.data.created_by = response["Created By"];
        this.data.modified_by = response["Modified By"];

        this.data.amount_mashed = response["Amount Mashed"];
        this.data.amount_mashed_remaining = response["Amount Mashed Remaining"];

        this.data.brix_in = response["Brix In"];
        this.data.brix_out = response["Brix Out"];
        this.data.description = response["Notes"];

        this.data.base_product = {
          id: response["Base Product ID"],
          label: response["Base Product"]
        };

        this.data.mash_tank = {
          id: response["Mash Tank ID"],
          label: response["Mash Tank Number"]
        };
      } catch (error) {
        console.log(error);

        this.$form.makeToastError(error);
      } finally {
        this.isLoading = false;
      }
    },
    onEditorBlur() {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return;

      this.updateField("description", this.data.description);
    },
    onFermentationsTableLoad(count) {
      if (count > 0)
        this.controls.tabs.fermentations.title = `Fermentations (${count})`;
    },
    onDistillationsTableLoad(count) {
      if (count > 0)
        this.controls.tabs.distillations.title = `Distillations (${count})`;
    },
    onMashingReportTabClick() {
      this.$refs["mashing-report"].drawCharts();
    }
  }
};
</script>

<style scoped>
.ck.ck-editor__editable_inline {
  border: 1px solid #e8e8e8;
  min-height: 150px !important;
}

.ck.ck-editor__editable_inline.ck-focused {
  background-color: #ffffed;
}
</style>
