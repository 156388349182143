<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <g-chart-custom
          :key="1"
          ref="chart1"
          title="Mash temperature"
          :subtitle="charts.chart1.subtitle"
          type="LineChart"
          :loading="charts.chart1.loading"
          :data="charts.chart1.chartData"
          :options="charts.chart1.chartOptions"
        >
        </g-chart-custom>
        <g-chart-custom
          ref="chart2"
          title="Mash Temp Set"
          :subtitle="charts.chart2.subtitle"
          type="LineChart"
          :loading="charts.chart2.loading"
          :data="charts.chart2.chartData"
          :options="charts.chart2.chartOptions"
        >
        </g-chart-custom>

        <g-chart-custom
          ref="chart3"
          title="Water Amount"
          :subtitle="charts.chart3.subtitle"
          type="LineChart"
          :loading="charts.chart3.loading"
          :data="charts.chart3.chartData"
          :options="charts.chart3.chartOptions"
        >
        </g-chart-custom>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import GChartCustom from "@/components/GChartCustom";

export default {
  name: "MashingReport",
  props: {
    mashId: {
      type: String,
      default: ""
    }
  },
  components: {
    GChartCustom
  },
  data: function() {
    return {
      dataSet: [],
      test: 0,
      charts: {
        chart1: {
          loading: false,
          chartData: null,
          chartOptions: {
            width: "100%",
            height: 300,
            // curveType: "function",
            chartArea: {
              width: "80%",
              height: "80%"
            },

            title: "",
            subtitle: "",
            series: {
              0: {
                targetAxisIndex: 0
              }
            },
            vAxis: {
              title: "Temp"
            },
            hAxis: {}
          }
        },
        chart2: {
          loading: false,
          chartData: null,
          chartOptions: {
            height: 300,
            chartArea: {
              width: "80%",
              height: "80%"
            },

            title: "",
            subtitle: "",
            series: {
              0: {
                targetAxisIndex: 0
              }
            },
            vAxis: {
              title: "Temp"
            },
            hAxis: {}
          }
        },
        chart3: {
          loading: false,
          chartData: null,
          chartOptions: {
            height: 300,
            chartArea: {
              width: "80%",
              height: "80%"
            },

            title: "",
            subtitle: "",
            series: {
              0: {
                targetAxisIndex: 0
              }
            },
            vAxis: {
              title: "Amount"
            },
            hAxis: {}
          }
        }
      }
    };
  },
  computed: {},
  updated() {},
  async mounted() {},
  methods: {
    getData() {},
    async drawChart1() {
      let headers = [
        [
          {
            type: "datetime",
            id: "Time",
            label: "Time"
          },
          {
            type: "number",
            id: "Mash temperature",
            label: "Mash temperature"
          }
        ]
      ];

      let data = this.dataSet.map(i => [
        new Date(Date.parse(i.date + " " + i.time)),
        i.mashtemp
      ]);

      this.charts.chart1.chartData = headers.concat(data);
    },
    async drawChart2() {
      let headers = [
        [
          {
            type: "datetime",
            id: "Time",
            label: "Time"
          },
          {
            type: "number",
            id: "Mash Temp Set",
            label: "Mash Temp Set"
          }
        ]
      ];

      let data = this.dataSet.map(i => [
        new Date(Date.parse(i.date + " " + i.time)),
        i.mashtempset
      ]);

      this.charts.chart2.chartData = headers.concat(data);
    },
    async drawChart3() {
      let headers = [
        [
          {
            type: "datetime",
            id: "Time",
            label: "Time"
          },
          {
            type: "number",
            id: "Amount Water",
            label: "Amount Water"
          }
        ]
      ];

      let data = this.dataSet.map(i => [
        new Date(Date.parse(i.date + " " + i.time)),
        i.amountwater
      ]);

      this.charts.chart3.chartData = headers.concat(data);
    },
    async drawCharts() {
      if (!this.dataSet.length) {
        this.charts.chart1.loading = true;
        this.charts.chart2.loading = true;
        this.charts.chart3.loading = true;

        this.dataSet = await this.$api.get(`mash/${this.mashId}/log-auto-data`);

        this.charts.chart1.loading = false;
        this.charts.chart2.loading = false;
        this.charts.chart3.loading = false;
      }

      if (this.dataSet.length === 0) return;

      this.drawChart1();
      this.drawChart2();
      this.drawChart3();
    }
  },
  watch: {}
};
</script>

<style></style>
