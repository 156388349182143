<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col>
        <b-button-group v-if="showAddButton">
          <b-button
            variant="outline-dark"
            title="Add distillation"
            @click="addItem()"
          >
            <font-awesome-icon icon="plus" /> Create
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(dataset)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            v-if="+props.row['Hearts Amount Remaining'] > 0"
            class="btn btn-warning btn-sm"
            @click="moveHearts(props.row)"
            title="New hearts to storage"
          >
            <strong>SH</strong>
          </button>
          <button
            v-if="+props.row['Tails Amount Remaining'] > 0"
            class="btn btn-warning btn-sm"
            @click="moveTails(props.row)"
            title="Move tails to storage"
          >
            <strong>ST</strong>
          </button>

          <button
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row.ID)"
            title="View"
          >
            <font-awesome-icon icon="eye" />
          </button>

          <button
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
            title="Edit"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row.ID)"
            title="Delete"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
export default {
  props: {
    mashId: {
      type: [String, Number],
      default: ""
    },
    fermentationId: {
      type: String,
      default: ""
    },
    showAddButton: {
      type: Boolean,
      default: true
    }
  },
  name: "DistillationTable",
  components: {},
  data: function() {
    return {
      dataset: {
        name: "distillation"
      },
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Fermentation Name",
            "Container Number",
            "Container Capacity",
            "Redistillation Status",
            "Amount Used",
            "PSI",
            "Condensation Temp",
            "Dephlegmator 1",
            "Dephlegmator 2",
            "Hearts %",
            "Hearts Amount",
            "Hearts Amount Remaining",
            "Tails %",
            "Tails Amount Remaining",
            "Start Date",
            "Start Time",
            "End Time",
            "Updated By",
            "Updated",
            "Actions"
            /*
            "PSI",
            "Condensation Temp",
            "Dephlegmator 1",
            "Dephlegmator 2",
            "Heads Amount",
            "Hearts Amount Remaining",
            "Tails Amount Remaining",
            "Distillation Inc",
            "Distillation Flowrate",

            "Heads %",
            "Hearts %",
            "Tails %",
            "Heads Pg",
            "Hearts Pg",
            "Tails Pg",
            "Created",
            "Created By",
            */
          ],
          filterable: [
            "ID",
            "Fermentation Name",
            "Container Number",
            "Container Capacity",
            "Redistillation Status",
            "Amount Used",
            "PSI",
            "Condensation Temp",
            "Dephlegmator 1",
            "Dephlegmator 2",
            "Hearts Amount",
            "Tails Amount",
            "Start Date",
            "Start Time",
            "End Time",
            "Updated By",
            "Updated"
            /*
            "PSI",
            "Condensation Temp",
            "Dephlegmator 1",
            "Dephlegmator 2",
            "Heads Amount",
            "Hearts Amount Remaining",
            "Tails Amount Remaining",
            "Distillation Inc",
            "Distillation Flowrate",

            "Heads %",
            "Hearts %",
            "Tails %",
            "Heads Pg",
            "Hearts Pg",
            "Tails Pg",
            "Created",
            "Created By",
            */
          ],
          hiddenColumns: [
            "PSI",
            "Condensation Temp",
            "Dephlegmator 1",
            "Dephlegmator 2"
          ],
          cellClasses: {
            "Hearts Amount": [
              {
                class: "bg-warning",
                condition: row => row["Hearts Amount"] == 0
              }
            ]
          },
          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: false,
          showCustomActions: true
        }
      }
    };
  },
  computed: {},
  mounted() {
    if (this.mashId) this.getData({ mashing_product_id: this.mashId });
  },
  methods: {
    onFilter() {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length;
    },

    async getData(payload) {
      let self = this;

      this.dataTable.isLoading = true;

      let response = [];

      try {
        response = await this.$api.post("distillation", payload);

        self.dataTable.isLoading = false;

        self.dataTable.dataSet = response;

        if (response.length === 0) return;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);
        self.dataTable.isLoading = false;
        self.$form.msgBoxOk("Error occured");
      }
    },
    addItem: function() {
      this.$router.push({
        name: "Distillation submission",
        params: {
          action: "create",
          fermentationId: this.fermentationId
        }
      });
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Distillation submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Distillation submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let item = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Distillation #${item["Fermentation Name"]} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`distillation/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },
    moveHearts: function(row) {
      this.$router.push({
        name: "Distillation submission",
        params: {
          action: "view",
          id: row.ID,
          moveHearts: +row["Hearts Amount"]
        }
      });
    },
    moveTails: function(row) {
      this.$router.push({
        name: "Distillation submission",
        params: {
          action: "view",
          id: row.ID,
          moveTails: +row["Tails Amount"]
        }
      });
    }
  },
  watch: {}
};
</script>

<style scoped></style>
