<template>
  <div class="animated fadeIn">
    <b-row v-if="this.showAddButton">
      <b-col>
        <b-button-group>
          <b-button
            variant="outline-dark"
            size="sm"
            :disabled="dataTable.isInserting"
            @click="addItem()"
            title="Add item"
          >
            <font-awesome-icon icon="plus" /> Add item
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <table-custom
      ref="dataTable"
      name="ct_mash_items"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @inline-update="onInlineUpdate"
      @inline-dropdown-change="onInlineDropdownChange"
      @column-update="onInlineColumnUpdate"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row)"
            title="Delete item"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import Vue from "vue";
import TableCustom from "@/components/TableCustom";

import { FORM_MODE } from "@/shared/constants";
import { mapState, mapActions } from "vuex";

export default {
  name: "MashItemsTable",
  props: {
    productId: {
      type: [String, Number],
      default: ""
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    mode: {
      type: [Number],
      default: FORM_MODE.VIEW
    }
  },
  components: {
    TableCustom
  },
  data: function() {
    return {
      isLoading: false,

      selectAll: false,
      selectedRows: [],
      dropdowns: {
        inventoryItem: [],
        lotNumber: []
      },
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          showEmpty: true,
          filterable: [],
          columns: [
            "Item Name",
            "Lot Number",
            "Start Time",
            "Amount Used",
            "Temperature",

            "Actions"
          ],
          autoTotalColumns: ["Amount Used"],
          editableColumns: [
            "Item Name",
            "Lot Number",
            "Start Time",
            "Amount Used",
            "Temperature"
          ],
          maskedColumns: [
            {
              name: "Start Time",
              mask: "11:11",
              placeholder: "hh:mm"
            }
          ],
          dropdownColumns: [
            { name: "Item Name", options: [] },
            { name: "Lot Number", options: [] }
          ],
          perPage: 50,
          showCustomActions: true,
          //showActions: true,
          showChildRows: true,
          selectableRows: this.selectableRows,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback
        },
        childRow: {
          showDescription: false
        }
      },

      dblclick: undefined,
      serverFilter: undefined
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    async initialize() {
      this.loadDictionaries();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.productId) {
        this.getData(this.productId);
      }
    },

    onInlineDropdownChange(e) {
      let self = this;
      let rowIndex = this.dataTable.dataSet.findIndex(
        i => i["ID"] === e.row["ID"]
      );
      let row = this.dataTable.dataSet[rowIndex];

      if (e.column === "Item Name") {
        row["Item ID"] = e.value.id;

        row["item_order_number"] = 0;
        row["Lot Number"] = "";

        this.$api
          .get(`inventory/item/${e.value.id}/positive-balance`)
          .then(response => {
            // self.dropdowns.lotNumber = response;

            self.dataTable.options.dropdownColumns.find(
              c => c.name === "Lot Number"
            ).options = response.map(u => ({
              id: u["HID"],
              label:
                "REM: " +
                u["Amount Remaining"] +
                " - " +
                u["Order Number"] +
                " - " +
                u["Order Date"] +
                " - " +
                u["Lot Number"]
            }));
          });
      }

      if (e.column === "Lot Number") {
        row["item_order_number"] = e.value.id;
      }

      Vue.set(this.dataTable.dataSet, rowIndex, row);
    },
    onInlineColumnUpdate() {
      /*
      let rowIndex = this.dataTable.dataSet.findIndex(i => i["ID"] === e.id);
      let row = this.dataTable.dataSet[rowIndex];

      row[e.name] = e.value;

      Vue.set(this.dataTable.dataSet, rowIndex, row);
      */
    },
    onInlineUpdate(e) {
      console.log("onInlineUpdate", JSON.stringify(e));

      let row = this.dataTable.dataSet.find(i => i["ID"] === e.id);

      if (e.column === "Item Name") {
        row["Item ID"] = e.value.id;
      }

      if (!e.value.label) row[e.column] = e.value;
      else row[e.column] = e.value.label;

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.$api
          .put(`mash/item/${row["ID"]}`, row)
          .then(response => {
            this.$form.makeToastInfo(response.message);

            this.getData();
          })
          .catch(function(error) {
            this.$form.makeToastError(error.message);
            this.getData();
          });
      }
    },
    loadDictionaries() {
      let self = this;

      const items = () => {
        self.$api.post("inventory/items").then(response => {
          self.dropdowns.inventoryItem = response;

          self.dataTable.options.dropdownColumns.find(
            c => c.name === "Item Name"
          ).options = response.map(u => ({
            id: u["ID"],
            label: u["Item Name"]
          }));
        });
      };

      Promise.all([items()]);
    },

    getDataSet() {
      return this.dataTable.dataSet;
    },
    addItem() {
      let newItem = {
        uid: this.$helpers.uuidv4(),
        ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        "Item ID": 0,
        "Item Name": "",
        item_order_number: 0,
        "Lot Number": "",
        "Start Time": "",
        "Amount Used": "",
        Temperature: ""
      };

      this.dataTable.dataSet = this.$refs.dataTable.insertNewRow(newItem);

      this.$nextTick(() => {
        this.$refs.dataTable.toggleChildRow(
          this.$constants.CUSTOM_TABLE.NEW_ROW_ID
        );
      });

      // this.dataTable.dataSet.push(newItem);

      this.dataTable.isInserting = true;
    },

    async saveNewRecordCallback(newRecord) {
      let self = this;

      if (!newRecord["Item Name"]) {
        self.$form.makeToastError("Please select an item");
        return;
      }
      if (!newRecord["Lot Number"]) {
        self.$form.makeToastError("Please select lot number");
        return;
      }
      if (!newRecord["Start Time"]) {
        self.$form.makeToastError("Please enter time in format HH:MM");
        return;
      }
      if (!newRecord["Amount Used"]) {
        self.$form.makeToastError("Please enter amount used");
        return;
      }
      if (!newRecord["Temperature"]) {
        self.$form.makeToastError("Please enter temperature");
        return;
      }

      let data = {
        id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID
      };

      data["product_id"] = this.productId;
      data["Item ID"] = newRecord["Item ID"];
      data["Start Time"] = newRecord["Start Time"];
      data["Amount Used"] = newRecord["Amount Used"];
      data["Temperature"] = newRecord["Temperature"];
      data["item_order_number"] = newRecord["item_order_number"];

      self.dataTable.isInserting = false;

      if (this.mode === this.$constants.FORM_MODE.VIEW && this.productId) {
        this.$api
          .post(`mash/${this.productId}/item`, data)
          .then(response => {
            self.$form.makeToastInfo(response.message);

            self.getData();
          })
          .catch(function(error) {
            self.$form.makeToastError(error.message);

            self.getData();
          });
      }

      return true;
    },

    async revertNewRecordCallback() {
      this.dataTable.isInserting = false;

      return true;
    },
    async deleteItem(row) {
      //let row = this.dataTable.dataSet.find(item => item.ID === row.ID);

      let confirm = await this.$form.showConfirmation(
        `Item #${row["Item Name"]} will be removed. Do you want to proceed?`
      );

      if (!confirm) return;

      let idProp =
        this.mode === this.$constants.FORM_MODE.CREATE ? "uid" : "ID";

      this.dataTable.dataSet = this.dataTable.dataSet.filter(
        i => i[idProp] !== row[idProp]
      );
      let self = this;

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        self.$api
          .delete(`mash/item/${row.ID}`)
          .then(() => {
            self.$form.makeToastInfo("Item deleted");
          })
          .catch(response => {
            console.log(response);

            self.$form.makeToastError(response.data.message);

            self.getData();
          });
      }
    },
    async getData(payload) {
      this.dataTable.isLoading = true;

      let url = `mash/${this.productId}/items`;

      let response = await this.$api.post(url, payload);

      this.rawData = response;

      this.dataTable.isLoading = false;

      this.dataTable.dataSet = this.rawData;
    }
  }
};
</script>

<style scoped>
/*
::v-deep table .multiselect {
  width: 300px;
}
*/
/*
::v-deep table .multiselect__content-wrapper {
  position: relative !important;
}
*/

/*
::v-deep .VueTables__table {
  overflow: visible !important;
}

::v-deep .VueTables .VueTables--client {
  overflow-x: scroll;
}

*/
::v-deep .table-responsive {
  overflow: visible !important;
}
::v-deep table {
  overflow: visible !important;
}

.ck.ck-editor__editable_inline {
  border: 1px solid #e8e8e8;
  min-height: 150px !important;
}

.ck.ck-editor__editable_inline.ck-focused {
  background-color: #ffffed;
}
</style>
