var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_c('h5', [_vm._v(" Mash details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.id.id,
      "value": _vm.data.id,
      "label": _vm.controls.id.label,
      "readonly": _vm.controls.id.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.base_product.id,
      "value": _vm.data.base_product,
      "label": _vm.controls.base_product.label,
      "readonly": _vm.controls.base_product.readonly,
      "options": _vm.controls.base_product.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.mash_tank.id,
      "value": _vm.data.mash_tank,
      "label": _vm.controls.mash_tank.label,
      "readonly": _vm.controls.mash_tank.readonly,
      "options": _vm.controls.mash_tank.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.date_started.id,
      "value-single": _vm.data.date_started,
      "label": _vm.controls.date_started.label,
      "readonly": _vm.controls.date_started.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_mashed.id,
      "value": _vm.data.amount_mashed,
      "label": _vm.controls.amount_mashed.label,
      "readonly": _vm.controls.amount_mashed.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_mashed_remaining.id,
      "value": _vm.data.amount_mashed_remaining,
      "label": _vm.controls.amount_mashed_remaining.label,
      "readonly": _vm.controls.amount_mashed_remaining.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.brix_in.id,
      "value": _vm.data.brix_in,
      "label": _vm.controls.brix_in.label,
      "readonly": _vm.controls.brix_in.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.brix_out.id,
      "value": _vm.data.brix_out,
      "label": _vm.controls.brix_out.label,
      "readonly": _vm.controls.brix_out.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created_by.id,
      "value": _vm.data.created_by,
      "label": _vm.controls.created_by.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified_by.id,
      "value": _vm.data.modified_by,
      "label": _vm.controls.modified_by.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created.id,
      "value": _vm.data.created,
      "label": _vm.controls.created.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified.id,
      "value": _vm.data.modified,
      "label": _vm.controls.modified.label,
      "readonly": true
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.description.id,
      "value": _vm.data.description,
      "label": _vm.controls.description.label,
      "readonly": _vm.controls.description.readonly,
      "is-text-area": true,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('mash-items-table', {
    ref: "mash-items-table",
    attrs: {
      "product-id": this.id,
      "mode": _vm.mode
    }
  })], 1)], 1), _vm.action !== 'preview' ? _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Mashing'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Mash submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Mash submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  }) : _vm._e()], 1), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.fermentations.title
    }
  }, [_c('fermentation-table', {
    attrs: {
      "mash-id": this.id,
      "show-add-button": +_vm.data.amount_mashed_remaining > 0
    },
    on: {
      "loaded": _vm.onFermentationsTableLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.distillations.title
    }
  }, [_c('distillation-table', {
    attrs: {
      "mash-id": this.id,
      "show-add-button": false
    },
    on: {
      "loaded": _vm.onDistillationsTableLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.report.title
    },
    on: {
      "click": _vm.onMashingReportTabClick
    }
  }, [_c('mashing-report', {
    ref: "mashing-report",
    attrs: {
      "mash-id": this.id
    }
  })], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }